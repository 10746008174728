.joinClasses {
  position: relative;
}
.joinClassesWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.queryByCourse {
  align-self: stretch;
  position: relative;
  font-size: 13px;
  color: #434343;
}
.searchForClassesToJoinParent {
  flex-direction: column;
  padding: 3px 0;
  align-items: flex-start;
  gap: 3px;
  font-size: 16px;
}
.frameChild,
.frameWrapper,
.joinClassesModalvariant2Inner,
.searchForClassesToJoinParent {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.frameChild {
  border: 1px solid #000;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  border-radius: 6px;
  flex-direction: row;
  padding: 6px 9px;
  align-items: center;
}
.frameWrapper,
.joinClassesModalvariant2Inner {
  flex-direction: column;
  align-items: flex-start;
}
.joinClassesModalvariant2Inner {
  padding: 0 0 6px;
}
.image17Icon {
  position: relative;
  width: 38px;
  height: 38px;
  object-fit: cover;
}
.cs159Fall {
  position: relative;
  font-size: 16px;
  color: #000;
}
.introToComputer {
  position: relative;
  display: flex;
  align-items: center;
  width: 168px;
}
.cs159Fall2022Parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.image17Parent {
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.classChip,
.classChipInner,
.image17Parent {
  display: flex;
  justify-content: flex-start;
}
.classChipInner {
  align-self: stretch;
  flex-direction: row;
  padding: 6px 9px;
  align-items: center;
}
.classChip {
  border-radius: 12px;
  background-color: #efefef;
  width: 310px;
  flex-direction: column;
  padding: 9px;
  box-sizing: border-box;
  align-items: flex-start;
}
.createNewClass {
  position: relative;
  font-size: 16px;
  font-family: Inter;
  color: #000;
  text-align: left;
}
.createNewClassWrapper,
.frameContainer,
.frameDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.createNewClassWrapper {
  flex: 1;
  flex-direction: column;
}
.frameContainer,
.frameDiv {
  flex-direction: row;
}
.frameContainer {
  flex: 1;
}
.frameDiv {
  align-self: stretch;
  padding: 6px 9px;
}
.classChip2,
.classChipParent,
.joinClassesModalvariant2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.classChip2 {
  cursor: pointer;
  border: 0;
  padding: 9px;
  background-color: #efefef;
  border-radius: 12px;
  width: 310px;
  box-sizing: border-box;
}
.classChipParent,
.joinClassesModalvariant2 {
  gap: 6px;
}
.classChipParent {
  align-self: stretch;
  padding: 0 0 6px;
  font-size: 13px;
  color: #434343;
  align-items: center;
  justify-content: center;
}
.joinClassesModalvariant2 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px;
  background-color: #fff;
  padding: 18px;
}
.joinClassModal {
  position: relative;
  width: 346px;
  height: 538px;
  max-width: 100%;
  max-height: 100%;
  overflow: visible;
  text-align: left;
  font-size: 10px;
  color: #000;
  font-family: Inter;
}
.classOptionsWrapper {
  height: 238px;
  max-height: 100%;
  overflow-y: scroll;
  align-items: center;
  justify-content: center;
}