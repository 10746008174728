.image17Icon {
    position: relative;
    width: 38px;
    height: 38px;
    object-fit: cover;
  }
  .cgt163Winter,
  .computerAidedDesign {
    position: relative;
    font-size: 16px;
    font-family: Inter;
    color: #000;
    text-align: left;
  }
  .computerAidedDesign {
    font-size: 13px;
    color: #434343;
    display: flex;
    align-items: center;
    width: 168px;
  }
  .cgt163Winter2023Parent,
  .image17Parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
  }
  .image17Parent {
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  .groupIcon {
    position: relative;
    width: 13.76px;
    height: 10.03px;
  }
  .line3horizontal1 {
    overflow: hidden;
    align-items: flex-start;
    justify-content: flex-start;
    transform: rotate(90deg);
    transform-origin: 0 0;
  }
  .frame,
  .iconmonstrCheckMark151,
  .line3horizontal1 {
    display: flex;
    flex-direction: column;
  }
  .frame {
    padding: 2px 0;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    transform-origin: 0 0;
  }
  .iconmonstrCheckMark151 {
    overflow: hidden;
    padding: 0 5px 0 0;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frameGroup {
    cursor: pointer;
    border: 0;
    padding: 6px 9px;
    background-color: #efefef;
    align-self: stretch;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .chatWithResources {
    position: relative;
  }
  .chatWithResourcesWrapper {
    display: flex;
    flex-direction: row;
    padding: 15px 14px 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .brain1Icon {
    position: relative;
    width: 23px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .learnConcepts {
    flex: 1;
    position: relative;
    font-size: 16px;
    font-family: Inter;
    color: #000;
    text-align: left;
  }
  .brain1Parent {
    cursor: pointer;
    border: 0;
    padding: 4px 0 4px 14px;
    background-color: #fff;
    align-self: stretch;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 1px;
  }
  .brain1ParentSelected {
    cursor: pointer;
    border: 0;
    padding: 4px 0 4px 14px;
    background-color: #f0f0f0;
    align-self: stretch;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 1px;
  }
  .brain1Parent:hover,
  .shareplay1Parent:hover {
    background-color: #f0f0f0;
  }
  .persontextrectangle1Icon {
    position: relative;
    width: 23px;
    height: 17.98px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .groupIcon1 {
    position: relative;
    width: 23px;
    height: 23px;
  }
  .frameContainer {
    width: 282px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .circlehexagongrid1Icon,
  .shareplay1Icon,
  .squaregrid3x31Icon {
    position: relative;
    width: 23px;
    height: 22px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .shareplay1Icon,
  .squaregrid3x31Icon {
    height: 22.58px;
  }
  .shareplay1Icon {
    height: 13px;
  }
  .shareplay1Parent {
    cursor: pointer;
    border: 0;
    padding: 4px 14px;
    background-color: #fff;
    align-self: stretch;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  .checkmarkicloud1Icon {
    position: relative;
    width: 23px;
    height: 17.4px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .frameParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 11px;
    color: #000;
    font-family: Inter;
    padding: 12px;
  }
  