/* .image17Icon {
  position: relative;
  width: 38px;
  height: 38px;
  object-fit: cover;
}
.cs159Fall {
  position: relative;
  font-size: 16px;
  font-family: Inter;
  color: #000;
  text-align: left;
}
.introToComputer {
  display: flex;
  align-items: center;
  width: 168px;
}
.introToComputer,
.studentsNovember {
  position: relative;
  font-size: 13px;
  font-family: Inter;
  color: #434343;
  text-align: left;
}
.cs159Fall2022Parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.image17Parent {
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.classChip,
.classChipInner,
.image17Parent {
  display: flex;
  justify-content: flex-start;
}
.classChipInner {
  align-self: stretch;
  flex-direction: row;
  padding: 6px 9px;
  align-items: center;
}
.classChip {
  cursor: pointer;
  border: 0;
  padding: 9px;
  background-color: #efefef;
  border-radius: 12px;
  width: 310px;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
}
.classChipSelected {
  cursor: pointer;
  border: 0;
  padding: 9px;
  background-color: #cfcfcf;
  border-radius: 12px;
  width: 310px;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
} */

.freshmanYear20212023 {
  position: relative;
  font-size: 16px;
  font-family: Inter;
  color: #000;
  text-align: left;
  max-height: 38px; /* Set the maximum height for two lines */
  overflow: hidden; /* Hide any overflowed text */
  /* white-space: nowrap; Prevent text from wrapping */
}

.ellipsis1Icon {
  position: relative;
  width: 3px;
  height: 14px;
  overflow: hidden;
  flex-shrink: 0;
}
.ellipsis1Wrapper,
.freshmanYear20212023Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 275px;
}
.ellipsis1Wrapper {
  padding: 0 10px 0 10px;
}
.freshmanYear20212023Parent {
  align-self: stretch;
  gap: 10px;
  width: 100%;
}
.resourceChipInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.image9Icon {
  position: relative;
  /* width: 31px;
  height: 43px; */
  max-width: 31px;
  max-height: 43px;
  width: auto;
  height: auto;
  object-fit: cover;
}
.pages23,
.uploadedByJames {
  position: relative;
  font-size: 13px;
  font-family: Inter;
  color: #434343;
  text-align: left;
}
.uploadedByJames {
  display: flex;
  align-items: center;
  width: 168px;
}
.pages23DaysAgoParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.image9Parent {
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.image9Parent,
.resourceChip,
.resourceChipChild {
  display: flex;
  justify-content: flex-start;
}
.resourceChipChild {
  align-self: stretch;
  flex-direction: row;
  padding: 6px 9px 0 0;
  align-items: center;
}
.resourceChip {
  cursor: pointer;
  border: 0;
  padding: 18px;
  padding-right: 8px;
  background-color: #efefef;
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.resourceChip:hover {
  background-color: #e7e7e7;
}
