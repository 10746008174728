.image17Icon {
  position: relative;
  width: 38px;
  height: 38px;
  object-fit: cover;
}
.cs159Fall {
  position: relative;
  font-size: 16px;
  font-family: Inter;
  color: #000;
  text-align: left;
}
.introToComputer {
  display: flex;
  align-items: center;
  width: 168px;
}
.introToComputer,
.studentsNovember {
  position: relative;
  font-size: 13px;
  font-family: Inter;
  color: #434343;
  text-align: left;
}
.cs159Fall2022Parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.image17Parent {
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.classChip,
.classChipInner,
.image17Parent {
  display: flex;
  justify-content: flex-start;
}
.classChipInner {
  align-self: stretch;
  flex-direction: row;
  padding: 6px 9px;
  align-items: center;
}
.classChip {
  cursor: pointer;
  border: 0;
  padding: 9px;
  background-color: #efefef;
  border-radius: 12px;
  width: 310px;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
}
.classChipSelected {
  cursor: pointer;
  border: 0;
  padding: 9px;
  background-color: #cfcfcf;
  border-radius: 12px;
  width: 310px;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
}
