.image17Icon {
  width: 38px;
  height: 38px;
  object-fit: cover;
}
.chatWithClass,
.getAnswersTo,
.image17Icon {
  position: relative;
}
.getAnswersTo {
  font-size: 13px;
  color: #434343;
  display: flex;
  align-items: center;
  /* width: 210px; */
}
.chatWithClassNotesParent,
.image17Parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.image17Parent {
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.vectorIcon {
  position: relative;
  width: 23px;
  height: 18px;
}
.iconmonstrCheckMark152 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent {
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  padding: 6px 3px 6px 9px;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: 16px;
  color: #000;
  font-family: Inter;
}
.frameParent:hover {
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  padding: 6px 3px 6px 9px;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: 16px;
  color: #000;
  font-family: Inter;
  background-color: #f0f0f0;
}
