.image17Icon {
    position: relative;
    width: 38px;
    height: 38px;
    object-fit: cover;
  }
  .cgt163Winter,
  .computerAidedDesign {
    position: relative;
    font-size: 16px;
    font-family: Inter;
    color: #000;
    text-align: left;
  }
  .computerAidedDesign {
    font-size: 13px;
    color: #434343;
    display: flex;
    align-items: center;
    width: 168px;
  }
  .cgt163Winter2023Parent,
  .image17Parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
  }
  .image17Parent {
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  .groupIcon {
    position: relative;
    width: 13.76px;
    height: 10.03px;
  }
  .line3horizontal1 {
    overflow: hidden;
    align-items: flex-start;
    justify-content: flex-start;
    transform: rotate(90deg);
    transform-origin: 0 0;
  }
  .frame,
  .iconmonstrCheckMark151,
  .line3horizontal1 {
    display: flex;
    flex-direction: column;
  }
  .frame {
    padding: 2px 0;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    transform-origin: 0 0;
  }
  .iconmonstrCheckMark151 {
    overflow: hidden;
    padding: 0 5px 0 0;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frameGroup {
    cursor: pointer;
    border: 0;
    padding: 6px 9px;
    background-color: #efefef;
    align-self: stretch;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .chatWithResources {
    position: relative;
  }
  .chatWithResourcesWrapper {
    display: flex;
    flex-direction: row;
    padding: 15px 14px 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .brain1Icon {
    position: relative;
    width: 23px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .brain1Parent {
    cursor: pointer;
    border: 0;
    padding: 4px 0 4px 14px;
    background-color: #fff;
    align-self: stretch;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  .brain1Parent:hover,
  .questionmarkdiamond1:hover,
  .sharedwithyou1:active,
  .sharedwithyou1:hover,
  .shareplay1Parent:hover,
  .squareandarrowup2:hover {
    background-color: #f0f0f0;
  }
  .persontextrectangle1Icon {
    position: relative;
    width: 23px;
    height: 17.98px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .completeAssignments {
    flex: 1;
    position: relative;
    font-size: 16px;
    font-family: Inter;
    color: #000;
    text-align: left;
  }
  .groupIcon1 {
    position: relative;
    width: 23px;
    height: 23px;
  }
  .frameContainer {
    /* width: 282px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .circlehexagongrid1Icon,
  .shareplay1Icon,
  .squaregrid3x31Icon {
    position: relative;
    width: 23px;
    height: 22px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .shareplay1Icon,
  .squaregrid3x31Icon {
    height: 22.58px;
  }
  .shareplay1Icon {
    height: 13px;
  }
  .shareplay1Parent {
    cursor: pointer;
    border: 0;
    padding: 4px 14px;
    background-color: #fff;
    align-self: stretch;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  .checkmarkicloud1Icon {
    position: relative;
    width: 23px;
    height: 17.4px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .frameParent,
  .sidebar,
  .sidebarInner {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .sidebar,
  .sidebarInner {
    background-color: #f3f3f3;
    align-items: center;
  }
  .sidebarInner {
    border-radius: 0 0 10px 0;
    padding: 12px 0 0;
  }
  .sidebar {
    width: 306px;
    padding: 0 12px;
    box-sizing: border-box;
  }
  .allergensfill1Icon {
    border-radius: 4px;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .generalGptTutor {
    position: relative;
    font-size: 13px;
    letter-spacing: 0.01em;
    font-weight: 500;
    font-family: Inter;
    color: #000;
    text-align: left;
  }
  .allergensfill1Parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
  }
  .groupIcon2 {
    position: relative;
    width: 11.38px;
    height: 8.3px;
  }
  .line3horizontal11 {
    display: flex;
    flex-direction: column;
    transform: rotate(90deg);
    transform-origin: 0 0;
  }
  .arrowshapeturnupbackward2,
  .line3horizontal11,
  .squareandarrowup3 {
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }
  .arrowshapeturnupbackward2 {
    width: 14px;
    height: 18px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    transform: rotate(-90deg);
    transform-origin: 0 0;
  }
  .squareandarrowup3 {
    cursor: pointer;
    border: 0;
    padding: 0 7px;
    background-color: #efefef;
    border-radius: 7px;
    height: 32px;
    display: none;
    flex-direction: row;
    box-sizing: border-box;
    gap: 18px;
  }
  .squareandarrowup3Wrapper {
    align-self: stretch;
    flex: 1;
    background-color: transparent;
    backdrop-filter: blur(40px);
    display: flex;
    flex-direction: row;
    padding: 0 45px;
    align-items: center;
    justify-content: flex-start;
  }
  .helpOurMission {
    position: relative;
    font-size: 13px;
    letter-spacing: 0.01em;
    font-weight: 500;
    font-family: Inter;
    color: #242424;
    text-align: center;
  }
  .frame1,
  .squareandarrowup2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .frame1 {
    align-self: stretch;
    padding: 0 5px;
    justify-content: flex-start;
  }
  .squareandarrowup2 {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    border-radius: 7px;
    height: 28px;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
  }
  .squareandarrowup2Wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .groupIcon3 {
    position: relative;
    width: 24.1px;
    height: 18.53px;
  }
  .sharedwithyou1 {
    cursor: pointer;
    border: 0;
    padding: 3.450000047683716px;
    background-color: transparent;
    border-radius: 7px;
    width: 38px;
    height: 28px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .questionmarkapp2Icon {
    position: relative;
    width: 18.01px;
    height: 17.94px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .image15,
  .questionmarkdiamond1 {
    cursor: pointer;
    border: 0;
    background-color: transparent;
  }
  .questionmarkdiamond1 {
    padding: 3.450000047683716px;
    border-radius: 7px;
    width: 33px;
    height: 28px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .image15 {
    padding: 0;
    position: relative;
    border-radius: 67px;
    width: 32px;
    height: 32px;
    /* background-image: url(/public/image15@3x.png); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  .image15Wrapper {
    display: flex;
    flex-direction: row;
    padding: 0 7px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frameParent3 {
    flex: 1;
    background-color: transparent;
    padding: 10px 2.5px;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
  }
  .frameParent2,
  .frameParent3,
  .header {
    align-self: stretch;
    backdrop-filter: blur(40px);
    display: flex;
    flex-direction: row;
  }
  .frameParent2 {
    flex: 1;
    background-color: transparent;
    align-items: center;
    justify-content: flex-end;
  }
  .header {
    background-color: #fafafa;
    height: 54px;
    padding: 10px 10px 10px 0;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .jackBlair {
    flex: 1;
    position: relative;
    font-weight: 600;
    font-size: 16px;
    font-family: Inter;
    color: #000;
    text-align: left;
  }
  .image15Parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    padding: 6px 0;
    align-items: center;
    justify-content: flex-start;
    gap: 9px;
  }
  .onceUponA {
    margin: 0;
  }
  .onceUponAContainer {
    align-self: stretch;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    font-family: Inter;
    /* color: #000; */
    text-align: left;
  }
  .frameParent6 {
    align-self: stretch;
    gap: 2px;
  }
  .frameParent6,
  .frameWrapper,
  .frameWrapper1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .frameWrapper1 {
    border-radius: 18px;
    background-color: #efefef;
    width: 886px;
    /* width: 100%; */
    padding: 9px 18px 18px;
    box-sizing: border-box;
  }
  .frameWrapper {
    /* width: 1206px; */
  }
  .onceUponAContainer1 {
    align-self: stretch;
    position: relative;
    font-weight: 500;
    color: rgba(35, 35, 35, 0.92);
    font-size: 16px;
    font-family: Inter;
    /* color: #000; */
    text-align: left;
  }
  .freshmanYear20212023 {
    align-self: stretch;
    position: relative;
    font-size: 16px;
    font-family: Inter;
    color: #000;
    text-align: left;
  }
  .image9Icon {
    position: relative;
    width: 31px;
    height: 43px;
    object-fit: cover;
  }
  .pages23 {
    position: relative;
    font-size: 13px;
    font-family: Inter;
    color: #434343;
    text-align: left;
  }
  .resourceChip,
  .resourceChipInner {
    display: flex;
    justify-content: flex-start;
  }
  .resourceChipInner {
    align-self: stretch;
    flex-direction: row;
    padding: 6px 9px 0 0;
    align-items: center;
  }
  .resourceChip {
    cursor: pointer;
    border: 0;
    padding: 18px;
    background-color: #efefef;
    border-radius: 12px;
    width: 285px;
    flex-direction: column;
    box-sizing: border-box;
    align-items: flex-start;
    gap: 6px;
  }
  .resourceChip:hover {
    background-color: #e7e7e7;
  }
  .frameParent7,
  .resourceChipParent {
    justify-content: flex-start;
    gap: 12px;
  }
  .resourceChipParent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .frameParent7 {
    max-width: 850px;
    width: 100%;
  }
  .frameChild,
  .frameParent5,
  .frameParent7,
  .frameWrapper2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  .frameWrapper2 {
    /* max-width: 1206px; */
    width: 100%;
    padding: 15px 0;
    box-sizing: border-box;
    justify-content: flex-start;
  }
  .frameChild,
  .frameParent5 {
    flex: 1;
    overflow-y: auto; /* Enable vertical scrolling for the container */
  }
  .frameParent5 {
    padding: 18px 0 150px;
    justify-content: flex-start;
    overflow-y: scroll; /* Enable vertical scrolling for the container */

    /* Make it fill its parent container horizontally */
    width: 100%;
    background-color: transparent;
  }
  .frameChild {
    border: 0;
    font-weight: 400;
    height: 25px;
    font-family: Inter;
    font-size: 16px;
    background-color: transparent;
    align-self: center;
    justify-content: space-between;
    user-select: none;
    outline: none;
  }
  .paperplanefill1Icon {
    position: relative;
    width: 17px;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .addResourceButton {
    cursor: pointer;
    border: 0;
    padding: 18px 0;
    background-color: #efefef;
    border-radius: 9px;
    width: 36px;
    height: 36px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .addResourceButton:enabled {  
    cursor: pointer;
    border: 0;
    padding: 18px 0;
    background-color: #50C7D7;
    border-radius: 9px;
    width: 36px;
    height: 36px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .frameParent10,
  .frameParent4,
  .frameParent9 {
    display: flex;
    align-items: center;
    /* justify-content: flex-start; */
  }
  .frameParent10 {
    border-radius: 9px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-sizing: border-box;
    max-width: 850px;
    max-height: 400px;
    width: 100%;
    flex-direction: row;
    padding: 9px 18px;
    gap: 21px;
    justify-content: space-between;
    justify-items: center;
    justify-self: end;
    
  }
  .frameParent4,
  .frameParent9 {
    align-self: stretch;
    flex-direction: column;
  }
  .frameParent9 {
    /* background-color: #fafafa; */
    background-image: linear-gradient(rgba(255,0,0,0), #f4f4f4, #f4f4f4);
    padding: 24px 0;
    gap: 16px;
    font-size: 13px;
    color: #434343;

    position: absolute;
    padding-left: 20px;
    padding-right: 20px;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .frameParent4 {
    flex: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Hide overflow to prevent scrolling on the whole page */
    min-height: calc(100vh - 54px);
    max-height: calc(100vh - 54px);

    position: relative;
  }
  .headerParent,
  .learnConcepts {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .headerParent {
    align-self: stretch;
    flex: 1;
    background-color: #fafafa;
    flex-direction: column;
    font-size: 16px;
  }
  .learnConcepts {
    position: relative;
    background-color: #fff;
    /* width: 100%; */
    height: 1163px;
    overflow: hidden;
    flex-direction: row;
    text-align: left;
    font-size: 11px;
    color: #000;
    font-family: Inter;
  }

  .frameParent22 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 850px;
    /* width: 100%; */
    margin-bottom: 20px;
    margin-top: -12px;
    display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 12px;
  }