.myAiScholars {
  position: relative;
}
.myAiScholarsWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.image17Icon {
  position: relative;
  width: 38px;
  height: 38px;
  object-fit: cover;
}
.professorPete,
.simpleChatbotWith {
  position: relative;
  font-size: 16px;
  font-family: Inter;
  color: #000;
  text-align: left;
}
.simpleChatbotWith {
  align-self: stretch;
  font-size: 13px;
  color: #434343;
}
.professorPeteParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.image17Parent,
.myAiScholarsModalvariant2Inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.image17Parent {
  flex: 1;
  gap: 12px;
}
.myAiScholarsModalvariant2Inner {
  cursor: pointer;
  border: 0;
  padding: 6px 3px 6px 9px;
  background-color: transparent;
  border-radius: 9px;
  width: 282px;
  box-sizing: border-box;
}
.myAiScholarsModal,
.myAiScholarsModalvariant2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.myAiScholarsModalvariant2 {
  border-radius: 12px;
  background-color: #fff;
  padding: 18px;
  gap: 6px;
}
.myAiScholarsModal {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: visible;
  text-align: left;
  font-size: 10px;
  color: #000;
  font-family: Inter;
}
