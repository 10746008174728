.freshmanYear20212023 {
    position: relative;
    font-size: 16px;
    font-family: Inter;
    color: #000;
    text-align: left;
    min-height: 38px;
  }
  .ellipsis1Icon {
    position: relative;
    width: 3px;
    height: 14px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .ellipsis1Wrapper,
  .freshmanYear20212023Parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 275px;
  }
  .ellipsis1Wrapper {
    padding: 0 5px 0 10px;
  }
  .freshmanYear20212023Parent {
    align-self: stretch;
    gap: 10px;
    width: 100%;
    max-lines: 2;
  }
  .resourceChipInner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
  .image9Icon {
    position: relative;
    /* width: 31px;
    height: 43px; */
    max-width: 31px;
    max-height: 43px;
    width: auto;
    height: auto;
    object-fit: cover;
  }
  .pages23,
  .uploadedByJames {
    position: relative;
    font-size: 13px;
    font-family: Inter;
    color: #434343;
    text-align: left;
  }
  .uploadedByJames {
    display: flex;
    align-items: center;
    /* width: 168px; */
  }
  .pages23DaysAgoParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
  }
  .image9Parent {
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  .image9Parent,
  .resourceChip,
  .resourceChipChild {
    display: flex;
    justify-content: flex-start;
  }
  .resourceChipChild {
    align-self: stretch;
    flex-direction: row;
    padding: 0px 9px 0 0;
    align-items: center;
  }
  .resourceChip {
    cursor: pointer;
    border: 0;
    padding-left: 18px;
    padding-right: 8px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #efefef;
    border-radius: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 310px;
    /* height: 86px; */
  }
  .resourceChip:hover {
    background-color: #e7e7e7;
  }
  
  .modernWay {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical;
 }