.myClasses {
  position: relative;
}
.myClassesWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.image17Icon {
  position: relative;
  width: 38px;
  height: 38px;
  object-fit: cover;
}
.linearAlgebra,
.ma265Spring {
  position: relative;
  font-size: 16px;
  font-family: Inter;
  color: #000;
  text-align: left;
}
.linearAlgebra {
  font-size: 13px;
  color: #434343;
  display: flex;
  align-items: center;
  width: 168px;
}
.image17Parent,
.ma265Spring2022Parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.image17Parent {
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.vectorIcon {
  position: relative;
  width: 23px;
  height: 18px;
}
.iconmonstrCheckMark151 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameGroup,
.frameParent {
  cursor: pointer;
  border: 0;
  padding: 6px 3px 6px 9px;
  background-color: #fff;
  align-self: stretch;
  border-radius: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.frameParent:hover {
  background-color: #f0f0f0;
}
.frameGroup {
  padding: 6px 9px;
  background-color: #efefef;
}
.myClassesModal,
.myClassesModalvariant3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.myClassesModalvariant3 {
  border-radius: 12px;
  background-color: #fff;
  padding: 18px;
  justify-content: flex-start;
  gap: 6px;
}
.myClassesModal {
  position: relative;
  overflow: visible;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  font-size: 10px;
  color: #000;
  font-family: Inter;
}
