.resourceType {
    align-self: stretch;
    position: relative;
  }
  .classSpecificParent,
  .resourceTypeParent {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .classSpecificParent {
    border-radius: 6px;
    border: 1px solid #000;
    flex-direction: row;
    padding: 6px 9px;
    gap: 10px;
  }
  .resourceTypeParent {
    flex-direction: column;
    gap: 3px;
  }
  .addResourceModalvariant2Inner {
    flex-direction: column;
    color: #434343;
  }
  .addResourceModalvariant2Child,
  .addResourceModalvariant2Inner,
  .frameChild {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frameChild {
    border: 1px solid #000;
    font-family: Inter;
    font-size: 16px;
    background-color: transparent;
    border-radius: 6px;
    flex-direction: row;
    padding: 6px 9px;
  }
  .addResourceModalvariant2Child {
    flex-direction: column;
    padding: 0 0 3px;
    color: #434343;
  }
  .frameItem {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 3px;
    flex-shrink: 0;
  }
  .frameDiv {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 5px 0 10px;
    align-items: center;
    justify-content: center;
  }
  .linkResource {
    position: relative;
  }
  .linkResourceWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 10px;
  }
  .pasteAPublicContainer {
    align-self: stretch;
    position: relative;
    font-size: 13px;
    color: #434343;
  }
  .addResourceModalvariant2Inner1,
  .howToLinkAResourceParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .howToLinkAResourceParent {
    padding: 3px 0;
    gap: 3px;
    font-size: 16px;
  }
  .addResourceModalvariant2Inner1 {
    padding: 0 0 6px;
    color: #434343;
  }
  .linkResource1 {
    position: relative;
    font-size: 16px;
    font-family: Inter;
    color: #000;
    text-align: left;
  }
  .classChip,
  .linkResourceContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .linkResourceContainer {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: #50C7D7;
    align-self: stretch;
    border-radius: 9px;
    height: 41px;
    flex-direction: row;
  }
  .classChip {
    border-radius: 12px;
    background-color: #efefef;
    width: 310px;
    height: 100px;
    flex-direction: column;
    padding: 9px;
    box-sizing: border-box;
  }
  .addResourceModalvariant2,
  .classChipWrapper {
    display: flex;
    flex-direction: column;
    padding: 3px 0;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .addResourceModalvariant2 {
    align-self: stretch;
    border-radius: 9px;
    background-color: #fff;
    padding: 18px;
    gap: 6px;
  }
  .createResourceModal {
    position: relative;
    width: 346px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    text-align: left;
    font-size: 13px;
    color: #000;
    font-family: Inter;
  }
  