.shareClass {
  position: relative;
}
.shareClassWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 10px;
}
.image17Icon {
  position: relative;
  width: 38px;
  height: 38px;
  object-fit: cover;
}
.linearAlgebra {
  position: relative;
  font-size: 13px;
  color: #434343;
  display: flex;
  align-items: center;
  width: 168px;
}
.image17Parent,
.ma265Spring2022Parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.image17Parent {
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.vectorIcon {
  position: relative;
  width: 23px;
  height: 18px;
}
.iconmonstrCheckMark151 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 6px 3px 6px 9px;
  align-items: center;
  justify-content: space-between;
}
.frameChild {
  height: 3px;
  width: 282px;
  background-color: #efefef;
  border-radius: 3px;
}
.classCommunityModalvariant3Inner {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 5px 0 10px;
  align-items: center;
  justify-content: center;
}
.chatsWrapper,
.classMembersParent {
  display: flex;
  flex-direction: column;
  padding: 6px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.classMembersParent {
  padding: 6px 0;
  gap: 2px;
}
.image15Icon {
  position: relative;
  border-radius: 67px;
  width: 38px;
  height: 38px;
  object-fit: cover;
}
.frameDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 6px 3px 6px 9px;
  align-items: center;
  justify-content: flex-start;
}
.classCommunityModalvariant3,
.classModal {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.classCommunityModalvariant3 {
  border-radius: 12px;
  background-color: #fff;
  flex-direction: column;
  padding: 18px;
  gap: 6px;
}
.classModal {
  position: relative;
  flex-direction: row;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: 16px;
  color: #000;
  font-family: Inter;
}
