.help {
  position: relative;
}
.helpWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 10px;
}
.image13Icon {
  position: relative;
  width: 34px;
  height: 39px;
  object-fit: cover;
}
.aShortYoutube,
.whatIsBoilergpt {
  position: relative;
  font-size: 16px;
  font-family: Inter;
  color: #000;
  text-align: left;
}
.aShortYoutube {
  font-size: 13px;
  color: #434343;
  display: flex;
  align-items: center;
  width: 220px;
}
.whatIsBoilergptParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.helpModalvariant2Child,
.helpModalvariant2Inner,
.image13Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.image13Parent {
  gap: 12px;
}
.helpModalvariant2Child,
.helpModalvariant2Inner {
  cursor: pointer;
  border: 0;
  padding: 6px 3px 6px 9px;
  background-color: transparent;
  align-self: stretch;
  border-radius: 9px;
}
.helpModalvariant2Child:hover,
.helpModalvariant2Inner:hover {
  background-color: #efefef;
}
.theStudentsDo {
  align-self: stretch;
  position: relative;
  font-size: 13px;
  color: #434343;
}
.whoMakesBoilergptGroup {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 6px 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.whatToHelp {
  align-self: stretch;
  position: relative;
}
.helpModalvariant2 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 18px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}
.helpModal {
  position: relative;
  width: 318px;
  height: 513px;
  max-width: 100%;
  max-height: 100%;
  overflow: visible;
  text-align: left;
  font-size: 16px;
  color: #000;
  font-family: Inter;
}
