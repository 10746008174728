.title {
    position: relative;
    font-size: 45px;
    line-height: 105.02%;
    display: flex;
    color: #000;
    align-items: center;
    justify-content: center;
    width: 726px;
  }
  .frameChild,
  .frameInner {
    position: relative;
    border-radius: 13px;
    background-color: #d9d9d9;
    width: 148px;
    height: 6px;
  }
  .frameInner {
    background-color: #000;
  }
  .rectangleParent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
  }
  .boilergptIsNot {
    justify-content: center;
  }
  .boilergptIsNotNot {
    justify-content: center;
    margin-bottom: -40px;
  }
  .asABoilermaker,
  .boilergptIsNot,
    .boilergptIsNotNot,
  .iUnderstandThat {
    position: relative;
    display: flex;
    align-items: center;
    width: 578px;
    gap: 10px;
  }
  .asABoilermaker {
    font-size: 34px;
    justify-content: center;
  }
  .iUnderstandThat {
    text-align: left;
  }
  .launchBoilergpt {
    position: relative;
    font-size: 16px;
    font-family: Inter;
    color: #000;
    text-align: left;
  }
  .googleContinue,
  .onboardingScreen,
  .onboardingScreen1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .googleContinue {
    cursor: pointer;
    border: 1px solid #000;
    padding: 0;
    background-color: #fff;
    border-radius: 9px;
    box-sizing: border-box;
    width: 330px;
    height: 41px;
    flex-direction: row;
  }
  .onboardingScreen,
  .onboardingScreen1 {
    flex-direction: column;
  }
  .onboardingScreen1 {
    border-radius: 22px;
    width: 963px;
    /* height: 100vh; For 100% screen height */
    gap: 20px;
  }
  .onboardingScreen {
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 100vh; /* For 100% screen height */
    text-align: center;
    font-size: 13px;
    color: #434343;
    font-family: Inter;
  }
  