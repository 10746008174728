.myAccount {
  position: relative;
}
.myAccountWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameChild {
  height: 3px;
  width: 282px;
  background-color: #efefef;
  border-radius: 3px;
}
.myAccountModalvariant3Inner {
  width: 282px;
  display: flex;
  flex-direction: column;
  padding: 5px 0 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.chatsWrapper,
.frameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent {
  padding: 6px 0;
  gap: 6px;
  font-size: 16px;
}
.exportMyData {
  position: relative;
  font-size: 16px;
  font-family: Inter;
  color: #000;
  text-align: left;
}
.exportMyDataWrapper,
.signOutWrapper {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: #efefef;
  border-radius: 9px;
  width: 282px;
  height: 41px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.signOutWrapper {
  background-color: #e43e52;
}
.myAccountModal,
.myAccountModalvariant3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.myAccountModalvariant3 {
  border-radius: 12px;
  background-color: #fff;
  flex-direction: column;
  padding: 18px;
  gap: 6px;
}
.myAccountModal {
  position: relative;
  flex-direction: row;
  max-width: 100%;
  max-height: 100%;
  overflow: visible;
  text-align: left;
  font-size: 10px;
  color: #000;
  font-family: Inter;
}
