.allergensfill1Icon {
  border-radius: 4px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.generalGptTutor {
  position: relative;
  font-size: 13px;
  letter-spacing: 0.01em;
  font-weight: 500;
  font-family: Inter;
  color: #000;
  text-align: left;
}
.allergensfill1Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
}
.groupIcon {
  position: relative;
  width: 11.38px;
  height: 8.3px;
}
.arrowshapeturnupbackward2,
.line3horizontal1 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* transform: rotate(90deg); */
  transform-origin: 0 0;
}
.arrowshapeturnupbackward2 {
  width: 14px;
  height: 18px;
  flex-shrink: 0;
  transform: rotate(-90deg);
}
.squareandarrowup3,
.squareandarrowup3Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.squareandarrowup3 {
  cursor: pointer;
  border: 0;
  padding: 0 7px;
  background-color: #efefef;
  border-radius: 7px;
  height: 32px;
  overflow: hidden;
  box-sizing: border-box;
  justify-content: center;
  gap: 14px;
}
.squareandarrowup3Wrapper {
  align-self: stretch;
  flex: 1;
  background-color: transparent;
  backdrop-filter: blur(40px);
  padding: 0 10px;
  justify-content: flex-start;
}
.helpOurMission {
  position: relative;
  font-size: 13px;
  letter-spacing: 0.01em;
  font-weight: 500;
  font-family: Inter;
  color: #242424;
  text-align: center;
}
.frame,
.squareandarrowup2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frame {
  align-self: stretch;
  padding: 0 5px;
  justify-content: flex-start;
}
.squareandarrowup2 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  border-radius: 7px;
  height: 28px;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
}
.questionmarkdiamond1:hover,
.sharedwithyou1:active,
.sharedwithyou1:hover,
.squareandarrowup2:hover {
  background-color: #f0f0f0;
}
.squareandarrowup2Wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.groupIcon1 {
  position: relative;
  width: 24.1px;
  height: 18.53px;
}
.sharedwithyou1 {
  cursor: pointer;
  border: 0;
  padding: 3.450000047683716px;
  background-color: transparent;
  border-radius: 7px;
  width: 38px;
  height: 28px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.questionmarkapp2Icon {
  position: relative;
  width: 18.01px;
  height: 17.94px;
  overflow: hidden;
  flex-shrink: 0;
}
.image15,
.questionmarkdiamond1 {
  cursor: pointer;
  border: 0;
  background-color: transparent;
}
.questionmarkdiamond1 {
  padding: 3.450000047683716px;
  border-radius: 7px;
  width: 33px;
  height: 28px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.image15 {
  padding: 0;
  position: relative;
  border-radius: 67px;
  width: 32px;
  height: 32px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.image15Wrapper {
  display: flex;
  flex-direction: row;
  padding: 0 7px;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameGroup {
  align-self: stretch;
  padding: 10px 2.5px;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}
.frameGroup,
.frameParent,
.frameWrapper {
  flex: 1;
  background-color: transparent;
  /* backdrop-filter: blur(40px); */
  display: flex;
  flex-direction: row;
  align-self: stretch;
}
.frameParent {
  align-self: stretch;
  align-items: center;
  justify-content: flex-end;
}
.frameWrapper {
  height: 54px;
  padding: 10px 10px 10px 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
}
