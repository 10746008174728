.image15Icon {
  border-radius: 67px;
  width: 38px;
  height: 38px;
  object-fit: cover;
}
.image15Icon,
.sarahJohnson,
.sinceMarch23 {
  position: relative;
}
.sinceMarch23 {
  font-size: 13px;
  color: #434343;
  display: flex;
  align-items: center;
  width: 168px;
}
.sarahJohnsonParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.image15Parent,
.myAccountModalvariant3Inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.image15Parent {
  gap: 12px;
}
.myAccountModalvariant3Inner {
  /* width: 282px; */
  padding: 6px 0;
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
  color: #000;
  font-family: Inter;
}
