.joinClasses {
  position: relative;
  font-size: 16px;
  font-family: Inter;
  color: #000;
  text-align: left;
}
.joinClassesWrapper {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: #50c7d7;
  border-radius: 9px;
  /* width: 282px; */
  width: 100%;
  height: 41px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
