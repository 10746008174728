.createClass {
  position: relative;
}
.createClassWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.courseCodeEx {
  align-self: stretch;
  position: relative;
}
.courseCodeExMa265Parent,
.frameChild,
.frameParent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameChild {
  border: 1px solid #000;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  border-radius: 6px;
  flex-direction: row;
  padding: 6px 9px;
}
.courseCodeExMa265Parent,
.frameParent {
  flex-direction: column;
  gap: 3px;
}
.frameParent {
  padding: 0 9px;
  gap: 6px;
  font-size: 13px;
  color: #434343;
}
.lineIcon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 3px;
  flex-shrink: 0;
}
.createClassModalInner {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 5px 0 10px;
  align-items: center;
  justify-content: center;
}
.image17Icon {
  position: relative;
  width: 38px;
  height: 38px;
  object-fit: cover;
}
.cs159Fall {
  position: relative;
  font-size: 16px;
  color: #000;
}
.introToComputer {
  position: relative;
  display: flex;
  align-items: center;
  width: 168px;
}
.cs159Fall2022Parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.classChipInner,
.image17Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.image17Parent {
  gap: 12px;
}
.classChipInner {
  align-self: stretch;
  padding: 6px 9px;
}
.classChip {
  border-radius: 12px;
  background-color: #efefef;
  width: 310px;
  padding: 9px;
  box-sizing: border-box;
}
.classChip,
.classChipWrapper,
.createClassModal1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.classChipWrapper {
  padding: 6px 0 0;
  font-size: 13px;
  color: #434343;
}
.createClassModal1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px;
  background-color: #fff;
  padding: 18px;
  gap: 6px;
}
.createClassModal {
  position: relative;
  width: 346px;
  height: 532px;
  max-width: 100%;
  max-height: 100%;
  overflow: visible;
  text-align: left;
  font-size: 10px;
  color: #000;
  font-family: Inter;
}
