.classResources {
  position: relative;
}
.freshmanYear20212023 {
  position: relative;
  font-size: 24px;
  font-family: Inter;
  color: #000;
  text-align: left;
}
.freshmanYear20212023ClassWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #EFEFEF;
}
.image9Icon {
  position: relative;
  width: 31px;
  height: 43px;
  object-fit: cover;
}
.pages23,
.uploadedByJames {
  position: relative;
  font-size: 13px;
  font-family: Inter;
  color: #434343;
  text-align: left;
}
.uploadedByJames {
  display: flex;
  align-items: center;
  width: 168px;
}
.pages23DaysAgoParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.addResourceButtonInner,
.image9Parent {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.image9Parent {
  display: flex;
  gap: 12px;
}
.addResourceButtonInner {
  align-self: stretch;
  display: none;
  padding: 6px 9px 0 0;
}
.addResourceButton {
  cursor: pointer;
  border: 0;
  padding: 18px;
  background-color: #efefef;
  border-radius: 9px;
  width: 36px;
  height: 36px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.classResourcesParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 21px;
  text-align: left;
  font-size: 45px;
  color: #000;
  font-family: Inter;
}
