.image17Icon {
  position: relative;
  width: 38px;
  height: 38px;
  object-fit: cover;
}
.cgt163Winter,
.computerAidedDesign {
  position: relative;
  font-size: 16px;
  font-family: Inter;
  color: #000;
  text-align: left;
}
.computerAidedDesign {
  font-size: 13px;
  color: #434343;
  display: flex;
  align-items: center;
  width: 168px;
}
.cgt163Winter2023Parent,
.image17Parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.image17Parent {
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.groupIcon {
  position: relative;
  width: 13.76px;
  height: 10.03px;
}
.line3horizontal1 {
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.frame,
.iconmonstrCheckMark151,
.line3horizontal1 {
  display: flex;
  flex-direction: column;
}
.frame {
  padding: 2px 0;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
  transform-origin: 0 0;
}
.iconmonstrCheckMark151 {
  overflow: hidden;
  padding: 0 5px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameGroup {
  cursor: pointer;
  border: 0;
  padding: 6px 9px;
  background-color: #efefef;
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.chatWithResources {
  position: relative;
}
.chatWithResourcesWrapper {
  display: flex;
  flex-direction: row;
  padding: 15px 14px 10px;
  align-items: flex-start;
  justify-content: flex-start;
}
.brain1Icon {
  position: relative;
  width: 23px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.learnConcepts {
  flex: 1;
  position: relative;
  font-size: 16px;
  font-family: Inter;
  color: #000;
  text-align: left;
}
.brain1Parent {
  cursor: pointer;
  border: 0;
  padding: 4px 0 4px 14px;
  background-color: #fff;
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.brain1Parent:hover,
.questionmarkdiamond1:hover,
.sharedwithyou1:active,
.sharedwithyou1:hover,
.shareplay1Parent:hover,
.squareandarrowup2:hover {
  background-color: #f0f0f0;
}
.persontextrectangle1Icon {
  position: relative;
  width: 23px;
  height: 17.98px;
  overflow: hidden;
  flex-shrink: 0;
}
.groupIcon1 {
  position: relative;
  width: 23px;
  height: 23px;
}
.frameContainer {
  width: 282px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.circlehexagongrid1Icon,
.shareplay1Icon,
.squaregrid3x31Icon {
  position: relative;
  width: 23px;
  height: 22px;
  overflow: hidden;
  flex-shrink: 0;
}
.shareplay1Icon,
.squaregrid3x31Icon {
  height: 22.58px;
}
.shareplay1Icon {
  height: 13px;
}
.shareplay1Parent {
  cursor: pointer;
  border: 0;
  padding: 4px 14px;
  background-color: #fff;
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.checkmarkicloud1Icon {
  position: relative;
  width: 23px;
  height: 17.4px;
  overflow: hidden;
  flex-shrink: 0;
}
.frameParent,
.sidebar,
.sidebarInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frameParent {
  align-self: stretch;
  align-items: flex-start;
}
.sidebar,
.sidebarInner {
  align-items: center;
}
.sidebarInner {
  align-self: stretch;
  border-radius: 0 0 10px 0;
  background-color: #fff;
  padding: 12px 0 0;
}
.sidebar {
  width: 306px;
  padding: 0 12px;
  box-sizing: border-box;
}
.allergensfill1Icon {
  border-radius: 4px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.generalGptTutor {
  position: relative;
  font-size: 13px;
  letter-spacing: 0.01em;
  font-weight: 500;
  font-family: Inter;
  color: #000;
  text-align: left;
}
.allergensfill1Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
}
.groupIcon2 {
  position: relative;
  width: 11.38px;
  height: 8.3px;
}
.arrowshapeturnupbackward2,
.line3horizontal11 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.arrowshapeturnupbackward2 {
  width: 14px;
  height: 18px;
  flex-shrink: 0;
  transform: rotate(-90deg);
}
.squareandarrowup3,
.squareandarrowup3Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.squareandarrowup3 {
  cursor: pointer;
  border: 0;
  padding: 0 7px;
  background-color: #efefef;
  border-radius: 7px;
  height: 32px;
  overflow: hidden;
  box-sizing: border-box;
  justify-content: center;
  gap: 18px;
}
.squareandarrowup3Wrapper {
  align-self: stretch;
  flex: 1;
  background-color: transparent;
  backdrop-filter: blur(40px);
  padding: 0 45px;
  justify-content: flex-start;
}
.helpOurMission {
  position: relative;
  font-size: 13px;
  letter-spacing: 0.01em;
  font-weight: 500;
  font-family: Inter;
  color: #242424;
  text-align: center;
}
.frame1,
.squareandarrowup2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frame1 {
  align-self: stretch;
  padding: 0 5px;
  justify-content: flex-start;
}
.squareandarrowup2 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  border-radius: 7px;
  height: 28px;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
}
.squareandarrowup2Wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.groupIcon3 {
  position: relative;
  width: 24.1px;
  height: 18.53px;
}
.sharedwithyou1 {
  cursor: pointer;
  border: 0;
  padding: 3.450000047683716px;
  background-color: transparent;
  border-radius: 7px;
  width: 38px;
  height: 28px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.questionmarkapp2Icon {
  position: relative;
  width: 18.01px;
  height: 17.94px;
  overflow: hidden;
  flex-shrink: 0;
}
.image15,
.questionmarkdiamond1 {
  cursor: pointer;
  border: 0;
  background-color: transparent;
}
.questionmarkdiamond1 {
  padding: 3.450000047683716px;
  border-radius: 7px;
  width: 33px;
  height: 28px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.image15 {
  padding: 0;
  position: relative;
  border-radius: 67px;
  width: 32px;
  height: 32px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.image15Wrapper {
  display: flex;
  flex-direction: row;
  padding: 0 7px;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent3 {
  flex: 1;
  padding: 10px 2.5px;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}
.frameParent2,
.frameParent3,
.header {
  align-self: stretch;
  background-color: transparent;
  backdrop-filter: blur(40px);
  display: flex;
  flex-direction: row;
}
.frameParent2 {
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}
.header {
  height: 54px;
  padding: 10px 10px 10px 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.freshmanYear20212023 {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  font-family: Inter;
  color: #000;
  text-align: left;
}
.image9Icon {
  position: relative;
  width: 31px;
  height: 43px;
  object-fit: contain;
}
.pages23 {
  position: relative;
  font-size: 13px;
  font-family: Inter;
  color: #434343;
  text-align: left;
}
.resourceChipInner {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 6px 9px 0 0;
  align-items: center;
  justify-content: flex-start;
}
.frameParent4,
.resourceChip {
  flex-direction: column;
  box-sizing: border-box;
}
.resourceChip {
  cursor: pointer;
  border: 0;
  padding: 18px;
  background-color: #efefef;
  border-radius: 12px;
  width: 285px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}
.resourceChip:hover {
  background-color: #e7e7e7;
}
.frameParent4 {
  flex: 1;
  background-color: #fafafa;
  padding: 28px 45px;
  padding-bottom: 0px;
  padding-right: 0px;
  display: flex;
  overflow-y: scroll;
  min-height: calc(100vh - 54px);
  max-height: calc(100vh - 54px);
  gap: 18px;
}
.classResources,
.frameParent4,
.headerParent {
  flex: 1;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.classResources {
  position: relative;
  background-color: #fff;
  width: 100%;
  /* overflow: hidden; */
  flex-direction: row;
  align-items: stretch;
  text-align: left;
  font-size: 11px;
  color: #000;
  font-family: Inter;
}

.frameParent5 {
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  gap: 12px;
}
.pasteAPublicContainer {
  align-self: stretch;
  position: relative;
  font-size: 13px;
  color: #434343;
}

.frameParent50 {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  flex-direction: row; /* Change direction to column for vertical layout */
  width: 100%;
  min-height: fit-content; /* Set minimum height based on content */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
}


.classResourcesParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 21px;
  text-align: left;
  font-size: 45px;
  color: #000;
  font-family: Inter;
  margin-bottom: 18px;
}
.classResources2 {
  position: relative;
}

.ultimateParent {
  margin-bottom: 12px;
  column-gap: 21px;
}



.recentlyUploaded {
  position: relative;
}
.recentlyUploadedWrapper {
  width: 1145px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.freshmanYear20212023 {
  align-self: stretch;
  position: relative;
}
.freshmanYear20212023ClassWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
}
.image13Icon {
  position: relative;
  width: 34px;
  height: 39px;
  object-fit: cover;
}
.linkedByJames {
  position: relative;
  display: flex;
  align-items: center;
  width: 168px;
}
.minutes23DaysAgoParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.image13Parent,
.resourceChipInner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.image13Parent {
  align-items: center;
  gap: 12px;
}
.resourceChipInner {
  align-self: stretch;
  padding: 6px 9px 6px 0;
  align-items: flex-start;
  color: #434343;
}
.arrowupheartfill2Icon {
  position: relative;
  width: 13.12px;
  height: 12.53px;
  overflow: hidden;
  flex-shrink: 0;
}
.usefulWrapper {
  height: 13.38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.arrowdownheartfill1Parent,
.arrowupheartfill2Parent {
  align-self: stretch;
  border-radius: 6px;
  background-color: #1ba018;
  display: flex;
  flex-direction: row;
  padding: 6px 9px;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.arrowdownheartfill1Parent {
  background-color: #da9717;
}
.span {
  color: #434343;
}
.dismissed {
  color: #da9717;
}
.endorsed23Container {
  position: relative;
  color: #000
}
.frameGroup {
  align-self: stretch;
  flex-direction: column;
  padding: 3px 0 0;
  gap: 9px;
}
.frameGroup,
.resourceChip,
.resourceChipParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: transparent;
  color: #ffffff;
}
.resourceChip {
  border-radius: 12px;
  width: 285px;
  flex-direction: column;
  padding: 18px;
  box-sizing: border-box;
  background-color: #efefef;
}
.resourceChipParent {
  width: 1145px;
  flex-direction: row;
  gap: 15px;
  font-size: 13px;
  background-color: #efefef;
}
.frameParent {
  gap: 18px;
}
.evaluateResources,
.frameParent,
.studentsEndorsedWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.studentsEndorsedWrapper {
  align-self: stretch;
}
.evaluateResources {
  position: relative;
  width: 100%;
  height: 1054px;
  padding: 28px 45px;
  box-sizing: border-box;
  gap: 24px;
  text-align: left;
  font-size: 45px;
  color: #ffffff;
  font-family: Inter;
}

.addResourceModalvariant2Inner1,
  .howToLinkAResourceParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .howToLinkAResourceParent {
    padding: 3px 0;
    gap: 3px;
    font-size: 16px;
  }
  .resourceType {
    align-self: stretch;
    position: relative;
  }
  .classSpecificParent,
  .resourceTypeParent {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .classSpecificParent {
    border-radius: 6px;
    border: 1px solid #000;
    flex-direction: row;
    padding: 6px 9px;
    gap: 10px;
  }
  .resourceTypeParent {
    flex-direction: column;
    gap: 3px;
  }

  .howToLinkAResourceParent {
    padding: 3px 0;
    gap: 3px;
    font-size: 16px;
  }
  .addResourceModalvariant2Inner1 {
    padding: 0 0 6px;
    color: #434343;
  }

  .linkResourceContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .linkResourceContainer {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: #50C7D7;
    align-self: stretch;
    border-radius: 9px;
    height: 41px;
    flex-direction: row;
  }
  .linkResource1 {
    position: relative;
    font-size: 16px;
    font-family: Inter;
    color: #000;
    text-align: left;
  }

  .frameChild {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frameChild {
    border: 1px solid #000;
    font-family: Inter;
    font-size: 16px;
    background-color: transparent;
    border-radius: 6px;
    flex-direction: row;
    padding: 6px 9px;
  }