.title {
  position: relative;
  width: 371px;
  height: 63px;
  flex-shrink: 0;
}
.featuresList,
.subtitle,
.title,
.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.subtitle {
  position: relative;
  font-size: 18px;
  width: 329px;
  height: 25px;
  flex-shrink: 0;
}
.featuresList,
.titleContainer {
  flex-direction: column;
  padding: 6px 0;
}
.featuresList {
  border-radius: 9px;
  background-color: #fff;
  overflow: hidden;
  padding: 12px 18px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  width: 329px;
}
.google64Copy1 {
  position: relative;
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.continueWithGoogle {
  position: relative;
  font-size: 16px;
  font-family: Inter;
  color: #000;
  text-align: left;
}
.googleContinue,
.microsoftContinue {
  cursor: pointer;
  border: 1px solid #000;
  padding: 0;
  background-color: #fff;
  border-radius: 9px;
  box-sizing: border-box;
  width: 330px;
  height: 41px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.microsoftContinue {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
}
.continueButtons,
.signInScreen {
  display: flex;
  flex-direction: column;
}
.continueButtons {
  padding: 6px 0;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
.signInScreen {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 100vh; /* Set the height to 100% of the viewport height */
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 60px;
  color: #000;
  font-family: Inter;
}

.notPurdue {
  color: #434343;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 10px;
}